const ActiveUserService = require("Services/ActiveUsers");

import PlotlyVue from "Components/ui/Plotly-vue"
import BaseIcon from "Components/ui/BaseIcon"
import BaseLoading from "Components/ui/BaseLoading"

export default {
  name: 'active-user',
  components: {
    BaseIcon,
    BaseLoading,
    PlotlyVue
  },
  data () {
    //dateString lets us get unix midnight today
    let today = new Date(this.dateString(new Date().getTime()));
    let tomorrow = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), (today.getUTCDate() + 1));
    let tmpStart = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 31);
    //need construct to translate dates to strings for inputs which is these timestamps that go through a date-string process to get the right format
    let grouping = { start: tmpStart, end: tomorrow };
    return {
      layout: {},
      chartLoading: true,
      chartData: undefined,
      summaryLoading: true,
      paidSummaryData: [],
      nonpaidSummaryData: [],
      startDate: this.dateString(grouping.start),
      endDate: this.dateString(today),
      grouping: grouping,
      period: { value: 0, minValue: 0, periodSelector: ['1d', '1w', '1m'] },
      now: new Date().getTime(),
    }
  },
  methods: {
    // expects time stamp. in ms
    dateString (ts) {
      return new Date(ts).toISOString().slice(0, 10);
    },
    // expects time stamp. in ms
    shortDateString (ts) {
      return new Date(ts).toUTCString().slice(0, 11);
    },

    //cancel request if period value changes, prevent duplicates
    limitUser () {
      let cancel = false;
      // if the diff is greater than a year select week agg, disable day agg
      if (this.grouping.end - this.grouping.start > 1000 * 60 * 60 * 24 * 365) {
        this.period.minValue = 1;
        this.period.message = "day aggregation not available for large amounts of data";
        if (this.period.value === 0) {
          this.period.value = 1;
          cancel == true;
        }
        // if the diff is greater than 120 days select week agg, pair days in groups
      } else if (this.grouping.end - this.grouping.start > 1000 * 60 * 60 * 24 * 120) {
        this.period.periodSelector[0] = "2d";
        this.period.minValue = 0;
        if (this.period.value === 0) {
          this.period.message = "Grouping days into pairs for performance";
        } else {
          this.period.message = "";
        }
      } else if (this.grouping.end - this.grouping.start < 1000 * 60 * 60 * 24 * 120) {
        this.period.periodSelector[0] = "1d";
        this.period.minValue = 0;
        this.period.message = "";
      }
      return cancel;

    },

    async updateChart () {
      this.chartLoading = true;
      if (this.limitUser()) {
        return;
      }
      // go likes seconds, js likes ms so TS / 1000 it is
      ActiveUserService.getActiveUsersInPeriod(parseInt(this.grouping.start / 1000), parseInt(this.grouping.end / 1000), this.period.periodSelector[this.period.value])
        .then(resp => {
          let data = resp.data.periods.sort((a, b) => b - a);
          this.processSummaryData(data);
          let xAxis = [];
          if (this.period.value == 0) {
            this.layout.xaxis.type = 'date'
            this.layout.xaxis.tickformat = '%b %_d'
            this.layout.xaxis.tickmode = 'array';
            //chart likes local time formatting, I don't. I add offset to not use local formatting becuase I'm right. it wrong
            xAxis = data.map(x => {
              return this.dateString(x.start_unix_seconds * 1000) //new Date((x.start_unix_seconds + new Date(x.start_unix_seconds * 1000).getTimezoneOffset() * 60) * 1000)
            })
            this.layout.xaxis.tickvals = xAxis;
          } else {
            this.layout.xaxis.type = 'category'
            this.layout.xaxis.tickformat = undefined
            this.layout.xaxis.tickmode = undefined;
            xAxis = data.map(x => {
              return this.shortDateString(x.start_unix_seconds * 1000) + " - " + this.shortDateString(x.end_unix_seconds * 1000)
            });
            this.layout.xaxis.tickvals = xAxis;
          }

          this.chartData = [{
            x: xAxis,
            y: data.map(x => x.paid_active_users),
            type: 'bar',
            name: 'Paid Active Users',
            marker: { color: '#52BAE2' },
          }, {
            x: xAxis,
            y: data.map(x => x.non_paid_active_users),
              type: 'bar',
            name: 'non-paid Active Users',
            marker: { color: '#6BEDD3' },
          }];

        }).catch((err) => {
          console.error(err);
        })
        .finally(() => { this.chartLoading = false; })
    },

    //retrieves the summary data for the view
    async processSummaryData (data) {
      this.summaryLoading = true;

      this.paidSummaryData = {}

      ActiveUserService.getActiveUsersSummary()
        .then(resp => {
          this.paidSummaryData = resp.data.paid_summary;
          this.nonpaidSummaryData = resp.data.non_paid_summary;

        }).catch((err) => {
          console.error(err);
        })
        .finally(() => { this.summaryLoading = false; })
    },
  },

  watch: {
    startDate (newVal, oldVal) {
      this.grouping.start = new Date(newVal).getTime();
    },
    endDate (newVal, oldVal) {
      this.grouping.end = new Date(newVal).setDate(new Date(newVal).getDate() + 1);
    },
    "period.value": function (newVal, oldVal) {
      this.updateChart();
    },
  },
  mounted () {
    this.layout = {
      showlegend: true,
      barmode: 'stack',
      legend: {
        x: 0,
        y: -0.2,
        orientation: "h"
      },
      height: 400,
      margin: {
        t: 50,
        b: 0
      },
      xaxis: {
        // visible: false
        tickmode: 'array',
        hoverformat: '%a %b %_d, %Y',
        autorange: true,
      },
    };

    this.updateChart();
  }
};