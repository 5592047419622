
import axios from 'axios';

const SERVICE_URL = __API_URL_V1 + 'metrics/';

// https://staging.api.talkatoo.com/v1/metrics/active_users?end_time=1616616956&start_time=1616017043&aggregation_period=1d

const getActiveUsersInPeriod = function (start, end, period) {

  return axios({
    method: 'GET',
    url: `${SERVICE_URL}active_users?end_unix_seconds=${end}&start_unix_seconds=${start}&aggregation_period=${period}`,
  })
}


const getActiveUsersSummary = function () {
  // https://staging.api.talkatoo.com/v1/metrics/active_users_summary
  return axios({
    method: 'GET',
    url: `${SERVICE_URL}active_users_summary`
  });
}

export {
  getActiveUsersInPeriod,
  getActiveUsersSummary,
}