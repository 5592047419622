<template>
  <div v-show="!loading" class="h-full flex flex-col">
    <div class="flex-grow flex flex-col justify-center relative">
      <!-- <div class="flex justify-center">
        <h2 class="my-12 flex text-center">Talkatoo Metrics Dashboard</h2>
      </div> -->

      <div class="top-0 my-32 absolute z-50 w-full text-center">
        <span class="font-bold text-5xl">
          Welcome to the Talkatoo Metrics Dashboard
        </span>
      </div>
      <!-- <div class="flex justify-center">
        <div class="w-156">
        <PlotlyVue :id="'chart'" :chartData="chartData" :layout="layout" displayModeBar="false" staticPlot="true"/>
        </div>
      </div> -->
      <div class="flex justify-center">
        <div class="container-blue flex flex-col w-120 h-80 p-12 justify-around z-50 rounded-2xl">
          <div class="flex justify-center">
            <img class="w-full px-4" alt="metrics dashboard logo" :src="require('Assets/images/logo.svg')" />
            </div>
          <div class="flex justify-center">
            <div id="google-signin-btn" :data-onsuccess="onSignIn" class="container-blue"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <img class="fixed bottom-0 w-full" style="z-index:-10" src="@/assets/images/clouds.svg"/>
    </teleport>
</template>

<style src="./Login.css"></style>
<script src="./Login.js"></script>
