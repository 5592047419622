<template >
  <div class=" w-80 bg-white h-full flex flex-col">
    <div class="flex mt-8 ml-4">
      <base-icon :text="initials" :themeName="'rare2'" :diameter="2.5" :borderWidth="2" />
      <div>
        <h5 class=" font-bold">{{$store.getters.name}}</h5>
        <span class="text-gray-400">{{$store.getters.email}}</span>
      </div>
    </div>
    <div class="flex flex-col flex-grow">
<!--
      <router-link class="mx-3 my-4 p-2 rounded-md text-lg font-semibold" to="/">
        <i class="material-icons pl-2 pr-4  ">home</i>
        Dashboard
      </router-link> -->
          <!-- change my-2 to mb-2 if we ever add in the rest of the metrics  -->
      <span class="mx-6 my-2 text-sm text-gray-400 font-semibold"> Active Users </span>

      <div class="flex flex-col  mb-4">
        <router-link class="nav-button" to="/">
          <span  class="pl-2 pr-4">
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2C22 3.1 21.1 4 20 4C19.82 4 19.65 3.98 19.49 3.93L15.93 7.48C15.98 7.64 16 7.82 16 8C16 9.1 15.1 10 14 10C12.9 10 12 9.1 12 8C12 7.82 12.02 7.64 12.07 7.48L9.52 4.93C9.36 4.98 9.18 5 9 5C8.82 5 8.64 4.98 8.48 4.93L3.93 9.49C3.98 9.65 4 9.82 4 10C4 11.1 3.1 12 2 12C0.9 12 0 11.1 0 10C0 8.9 0.9 8 2 8C2.18 8 2.35 8.02 2.51 8.07L7.07 3.52C7.02 3.36 7 3.18 7 3C7 1.9 7.9 1 9 1C10.1 1 11 1.9 11 3C11 3.18 10.98 3.36 10.93 3.52L13.48 6.07C13.64 6.02 13.82 6 14 6C14.18 6 14.36 6.02 14.52 6.07L18.07 2.51C18.02 2.35 18 2.18 18 2C18 0.9 18.9 0 20 0C21.1 0 22 0.9 22 2Z" fill="#7C7C7C"/>
            </svg>
          </span>
          Active Over Time
        </router-link>
        <!-- <router-link class=" nav-button" to="/churn-risk">
          <span  class="pl-2 pr-4">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.41177 2.25628V0C6.81178 0 4.70588 2.1256 4.70588 4.75C4.70588 7.3744 6.81178 9.5 9.41177 9.5V7.24372C8.04706 7.24372 6.94117 6.12747 6.94117 4.75C6.94117 3.37253 8.04706 2.25628 9.41177 2.25628Z" fill="#7C7C7C"/>
              <path d="M9.41177 10.6875C6.27057 10.6875 0 12.2788 0 15.4375V19H9.41177V16.7437H2.23529V15.4375C2.23529 14.6775 5.91765 12.9438 9.41177 12.9438V10.6875Z" fill="#7C7C7C"/>
              <path d="M10.5882 19H20V16.625C20 13.4663 13.7294 11.875 10.5882 11.875V19Z" fill="#7C7C7C"/>
              <path d="M10.5882 9.5C13.1882 9.5 15.2941 7.3744 15.2941 4.75C15.2941 2.1256 13.1882 0 10.5882 0V9.5Z" fill="#7C7C7C"/>
              </svg>
          </span>
          Comparisons
        </router-link>
        <router-link class="nav-button" to="/dev-tests">
          <i class="material-icons pl-2 pr-4  ">construction </i>

          Dev-Tests
        </router-link> -->
      </div>

      <!-- <span class="mx-4 my-2 text-sm text-gray-400 font-semibold"> Subscription Seat </span>

      <div class="flex flex-col">
        <router-link class=" nav-button" to="/seat-change">
        <span  class="pl-2 pr-4">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM4.5 7L8 3.5L11.5 7H9V11H7V7H4.5ZM15.5 13L12 16.5L8.5 13H11V9H13V13H15.5Z" fill="#7C7C7C"/>
          </svg>
        </span>
          Change
        </router-link>
        <router-link class=" nav-button" to="/utilization">
        <span  class="pl-2 pr-4">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 3.08V0C4 0.5 0 4.81 0 10C0 15.19 4 19.5 9 20V16.92C6 16.44 3 13.52 3 10C3 6.48 6 3.56 9 3.08ZM16.97 9H20C19.53 4 16 0.47 11 0V3.08C14 3.51 16.54 6 16.97 9ZM11 16.92V20C16 19.53 19.53 16 20 11H16.97C16.54 14 14 16.49 11 16.92Z" fill="#7C7C7C"/>
          </svg>
        </span>
          Utilization
        </router-link>
      </div> -->

    </div>
    <img class="px-6 py-8 border-gray-300 border-t"
      alt="metrics dashboard logo"
      :src="require('Assets/images/logo.svg')"
    />
  </div>
</template>

<script>
import BaseIcon from "Components/ui/BaseIcon.vue";

export default {
  name: "Sidebar",
  components: { BaseIcon },

  computed: {
    initials(){
      const names = this.$store.getters.name.split(" ");
      let initials = ""
      if (names.length >= 1){
        initials += names[0][0].toUpperCase();
      }
      if (names.length >= 2){
        initials += names[1][0].toUpperCase();
      }
      return initials;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
  @apply text-black;
  @apply no-underline;
}
i {
  @apply text-gray-500;
}

a:hover, a:hover  * {
  @apply text-white;
  @apply bg-main-alt;
  fill:white;
}
.router-link-active, .router-link-active  * {
  @apply bg-main-alt;
  @apply text-white;
  @apply cursor-default;
  fill:white;
}

.nav-button {
  @apply mx-4 my-1 p-2 rounded-md text-lg font-semibold;
}

</style>
