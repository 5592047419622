<template>
  <div v-on:click="action" class="base-icon" v-bind:style="baseStyles">
    <span
      v-if="icon === undefined"
      class="base-icon-text"
      v-bind:style="textStyles"
    >
      {{ text }}
    </span>
    <span v-else>
      <i
        class="material-icons icon-pos"
        v-bind:style="iconStyles"
        v-html="icon"
      >
      </i>
    </span>
    <div class="base-icon-background" v-bind:style="backgroundStyles"></div>
  </div>
</template>

<script>
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";
const { theme } = resolveConfig(tailwindConfig);

export default {
  name: "BaseIcon",
  props: {
    text: String,
    icon: String,
    themeName: String,
    diameter: Number,
    borderWidth: Number,
    fontSize: Number,
  },

  computed: {
    baseStyles() {
      return `border-color: ${theme.colors[this.themeName].dark};
              border-width: ${this.borderWidth ?? this.diameter / 1.25}px;
              height: ${this.diameter}rem;
              width: ${this.diameter}rem;`;
    },
    backgroundStyles() {
      return `background-color: ${theme.colors[this.themeName].dark};
               height: ${this.diameter}rem;
               width: ${this.diameter}rem;`;
    },
    textStyles() {
      //manual font size
      if (this.fontSize !== undefined) {
        return `color: ${theme.colors[this.themeName].dark};
                font-size: ${this.fontSize}rem;`;
      }
      //else use relative text length vs diameter font size
      return `color: ${theme.colors[this.themeName].dark};
              font-size: ${this.diameter / (1.25 * this.text.length)}rem;`;
    },

    iconStyles() {
      //manual font size
      if (this.fontSize !== undefined) {
        return `color: ${theme.colors[this.themeName].dark};
                font-size: ${this.fontSize}rem;`;
      }
      //else use relative size of diameter
      return `color: ${theme.colors[this.themeName].dark};
              font-size: ${this.diameter / 1.5}rem;`;
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style scoped>
.base-icon {
  @apply text-rare2-dark;
  @apply border-rare2-dark;
  @apply rounded-full;
  @apply mx-2;
  border: 0.125rem solid;
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  text-align: center;
}

.base-icon-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  position: absolute;
  user-select: none;
}

.base-icon-background {
  @apply text-rare2-dark;
  @apply bg-rare2-dark;
  @apply opacity-25;
  @apply rounded-full;
  width: 3.125rem;
  height: 3.125rem;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -50%);
  position: absolute;
}

.icon-pos {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
</style>