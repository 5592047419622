import { createStore } from 'vuex';

export default createStore({
  state: {
    loggedIn: false,
    gAuth: {},
    accessToken: '',
    name: '',
    email: '',
  },
  mutations: {
    auth_success: (state, auth_resp) => {
      state.loggedIn = true;
      state.accessToken = auth_resp.accessToken;
      state.name = auth_resp.name;
      state.email = auth_resp.email;
    },
    setGAuth: (state, gAuth) => {
      state.gAuth = gAuth;
    },
    setAccessToken: (state, token) => {
      state.accessToken = token;
    },

  },
  actions: {
  },
  modules: {

  },
  getters: {
    state: state => state,
    gAuth: state => state.gAuth,
    accessToken: state => state.accessToken,
    name: state => state.name,
    email: state => state.email,
    isLoggedIn: state => state.loggedIn,
    gauthIsSignedIn: state => state.gAuth.isSignedIn?.get() ?? false
  }
});
