import { createRouter, createWebHistory } from 'vue-router';
import ActiveUsers from 'Views/ActiveUsers/ActiveUsers.vue';
// import Dashboard from 'Views/Dashboard/Dashboard.vue';
import Login from 'Views/Login/Login.vue';

//lazy-load components for better performance
//https://router.vuejs.org/guide/advanced/lazy-loading.html#grouping-components-in-the-same-chunk
//Group components with webpackChunkName: "group-foo" in import function
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Dashboard,
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    // path: '/active-users',
    path: '/',
    name: 'Active Users',
    component: ActiveUsers,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      preAuth: true,
    }
  },
  // {
  //   path: '/churn-risk',
  //   name: 'Churn Risk',
  //   component: () => import('Views/ChurnRisk/ChurnRisk.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  // {
  //   path: '/seat-change',
  //   name: 'Seat Change',
  //   component: () => import('Views/SeatChange/SeatChange.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  // {
  //   path: '/utilization',
  //   name: 'Utilization',
  //   component: () => import('Views/Utilization/Utilization.vue'),
  // },
  // {
  //   path: '/dev-tests',
  //   name: 'dev-tests',
  //   component: () => import('Views/dev-test/dev-test.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
];

const router = createRouter({
  history: createWebHistory(__BASE_URL),
  routes
});

export default router;
