import { createApp, Vue } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';


axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

router.beforeEach((to, from, next) => {
  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const PreAuthPage = to.matched.some(record => record.meta.preAuth);

  if (PreAuthPage) {
    if (store.getters.isLoggedIn) {
      next({
        name: "/"
      })
    } else {
      next();
    }
  } else { // :pathMatch(.*)*
    next();
  }
});

axios.interceptors.request.use(config => {
  const token = store.getters.accessToken;
  // adding token header to all requests to the talkatoo api for folks with cookies disabled
  if (token && token != '' && (
    config.url.match(__API_URL_V1) !== null ||
    config.url.match(__API_URL_V2) !== null ||
    config.url.match(__API_URL_V3) !== null ||
    config.url.match(__API_URL_V4) !== null
  )) {
    config.headers['Authorization'] = token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, error => {

  if (error.response) {
    switch (error.response.status) {
      case 400:
        // toast messages
        break;
      case 401:
        // handle error
        break;
      case 403:
        // router.push('/').catch(err => { });
        break;
      default:
        console.log(error.response);
    }
  }
  return Promise.reject(error);
});


createApp(App).use(store).use(router).mount('#app');
