module.exports = {
  theme: {
    extend: {
      colors: {
        main: {
          DEFAULT: '#4C9DB8',
          lighter: '#97D6ED',
          light: '#52BAE2',
          alt: '#0386B7',
          dark: '#0F7A9E',
          darker: '#124C60',
        },
        neutral: {
          DEFAULT: "#7C7C7C",
          lighter: '#E5E5E5',
          light: '#C4C4C4',
          alt: '#375A65',
          dark: '$444444',
          darker: '#2C2C2C',
        },
        secondary: {
          DEFAULT: '#FFB300',
          lighter: '#FBC665',
          light: '#FBC665',
          dark: '#C48D2B',
          darker: '#765E33',
        },
        rare: {
          DEFAULT: '#7A98CC',
          lighter: '#D7E0FE',
          light: '#97ABED',
          alt: '#5273E2',
          dark: '#1B399F',
          darker: '#032CB7',
        },
        rare2: {
          DEFAULT: '#1B9F81',
          lighter: '#DCF9F3',
          light: '#6BEDD3',
          alt: '#2FDCB5',
          dark: '#03B78E',
          darker: '#1B9F81',
        },
        error: {
          DEFAULT: '#FF4E5A',
          light: '#ff8a92',
          lighter: '#ffbabf',
          dark: '#ad1c26',
        },
        red: {
          DEFAULT: '#FF0000',
          light: '#ff5863',
          lighter: '#ff939a',
          dark: '#ff0010',
        },
        pastel: {
          'yellow': '#D1ECF6',
          'green': '#C2E9F5',
          'yellow-light': '#FEF8EB',
          'yellow-lighter': '#FDF2DC',
          'green-light': '#E8FCF8',
          'green-lighter': '#E4F7F3',
          'purple-light': '#EEF2F9',
          'purple-lighter': '#E5EBF5',
          'red-light': '#F9EEEE',
          'red-lighter': '#F5E5E5'
        },
        charcoal: '#434343',
        white: '#FFFFFF',
        white2: '#FAFAFA',
      },
      margin: {
        '96': '24rem',
        '128': '32rem',
      },
      padding: {
        '96': '24rem',
        '128': '32rem',
      },
      width: {
        '80': '20rem',
        '84': '21rem',
        '88': '22rem',
        '92': '23rem',
        '96': '24rem',
        '120': '30rem',
        '128': '32rem',
        '156': '48rem',
      },
      height: {
        '80': '20rem',
        '84': '21rem',
        '88': '22rem',
        '92': '23rem',
        '120': '30rem',
        '128': '32rem',
        '156': '48rem',
      },
      minHeight: {
        '96': '24rem',
        '120': '30rem',
        '128': '32rem',
        '156': '48rem',
      },
      screens: {
        'sm': '640px',
        'md': '768px',
        'lg': '1024px',
        'xl': '1280px',
        '2xl': '1920px',
        '4xl': '2440px',
      },
    }
  },
  variants: {
    textColor: ['responsive', 'hover', 'focus', 'group-hover'],
  },
  darkMode: false,
  purge: {
    enabled: process.env.NODE_ENV !== 'development',
    content: [
    './src/**/*.html',
    './src/**/*.vue',
    './src/**/*.jsx',
  ]}

};
