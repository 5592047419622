
export default {
  name: "Login",
  data () {
    return {
      loading: true,
    };
  },
  beforeUnmount () {
    document.body.className = '';
  },
  mounted () {
    document.body.className = 'login';
    console.log("Welcome to the login");
    //app.vue does the inital auth work, if the user loads this page and are logged in, we just re-nav them
    if (this.$store.getters.gAuth?.isSignedIn?.get()) {
      this.$router.push("/");
    } else {
      //renders login button on the page
      //google is strict about branding https://developers.google.com/identity/branding-guidelines
      gapi.signin2.render("google-signin-btn", {
        'width': 330,
        'height': 60,
        'longtitle': true,
        'theme': 'dark',
        onsuccess: this.onSignIn,
        onfailure: this.onFailure,
      });
      this.loading = false;
    }
  },

  methods: {
    onSignIn (googleUser) {
      //signin only allows users in our org, after authentication get some info on the user
      const profile = googleUser.getBasicProfile();
      const authData = {
        name: profile.getName(),
        email: profile.getEmail(),
        accessToken: googleUser.getAuthResponse(true).access_token,
      };

      this.$store.commit("auth_success", authData);
      this.$router.push("/");

    },

    onFailure (e) {
      console.log("onFailure", e);
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function () {
        console.log('User signed out.');
      });
    },
  }
};