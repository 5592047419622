<template>

  <!-- date range selection -->
  <!-- <div class="container-blue inline-flex p-4 mb-4 ">

    <span class="m-auto mr-4 text-gray-500">Date Range </span>
    <input class="mr-6" type="date" v-model="startDate" :max="dateString(now)" @blur="updateChart" />
    <span class="text-lg m-auto mx-2 text-gray-500"> - </span>
    <input type="date" v-model="endDate" :max="dateString(now)" @blur="updateChart" />
  </div> -->

<!--
  data driven boxes
average,
rate of change on selection
-->


  <div class="container-blue w-full xl:w-5xl lg:w-4xl mb-6 py-6 px-12">
    <div class="flex justify-center text-gray-300 text-lg font-semibold py-4">Active Users Over Time</div>
    <!-- header for chart selection -->
    <div class="flex justify-between px-4 bg-gray-200">
      <div class="flex my-2">
        <button :class="[period.value == 0 ? 'btn-selection-active' : 'btn-selection', '']" @click="period.value = 0" :disabled="0 < period.minValue">Day</button>
        <button :class="[period.value == 1 ? 'btn-selection-active' : 'btn-selection', 'mx-2']" @click="period.value = 1">Week</button>
        <button :class="[period.value == 2 ? 'btn-selection-active' : 'btn-selection', '']" @click="period.value = 2">Month</button>
      <div class=" ml-4 text-gray-400">
        {{period.message}}
      </div>
      </div>

      <div class="flex">
        <span class="m-auto mx-4 text-gray-500">Date Range </span>
        <input class="mr-6" type="date" v-model="startDate" :max="dateString(now)" @blur="updateChart" />
        <span class="text-lg m-auto mx-2 text-gray-500"> - </span>
        <input type="date" v-model="endDate" :max="dateString(now)" @blur="updateChart" />
      </div>
    </div>
    <!-- chart -->
    <div style="height:26rem;">
      <BaseLoading v-if="chartLoading" />
      <PlotlyVue
        v-else-if="!chartLoading"
        :id="'test'"
        :chartData="chartData"
        :layout="layout"
      />
    </div>
  </div>
  <!-- bottom boxes -->
  <!-- hidden until we know what we want here -->
  <div class="grid grid-cols-3 grid-flow-col gap-4 mb-4 h-48" >
    <div class="container-blue col-span-1 ">
      <BaseLoading v-if="summaryLoading" />
      <div v-else class="summary-box p-4">
        <div class="text-gray-300 text-lg font-semibold">Active Users Last 24 hours</div>
        <h2>{{paidSummaryData.last_day}} <span class="text-gray-400 ml-8 "> (+{{nonpaidSummaryData.last_day}} Trials)</span></h2>
        <div class="text-rare2-dark flex text-2xl" v-if="false">
          <BaseIcon icon="north_east" themeName="rare2" :diameter="2" />
          <span class="ml-2"> +00 </span>
        </div>
      </div>
    </div>
    <div class="container-blue col-span-1 ">
      <BaseLoading v-if="summaryLoading" />
      <div v-else class="summary-box p-4">
        <div class="text-gray-300 text-lg font-semibold">Active Users This Week (Average, High/Low)</div>
        <h2>{{paidSummaryData.average_week}}  <span class="text-gray-400 ml-8 "> (+{{nonpaidSummaryData.average_week}} Trials)</span></h2>
        <!-- text-red-dark -->
        <div class=" flex justify justify-between text-2xl">
          <div class="flex" v-if="false" >
            <BaseIcon icon="south_east" themeName="red" :diameter="2" />
            <span class="ml-2"> +00 </span>
          </div>
          <span class="mr-4"> H: {{paidSummaryData.weekly_high}} / L: {{paidSummaryData.weekly_low}} <!-- <span class="font-thin text-gray-300 ml-10"> H: {{nonpaidSummaryData.weekly_high}} / L: {{nonpaidSummaryData.weekly_low}} </span> -->
          </span>
        </div>
      </div>
    </div>
    <div class="container-blue col-span-1 ">
      <BaseLoading v-if="summaryLoading" />
      <div v-else class="summary-box p-4">
        <div class="text-gray-300 text-lg font-semibold">Active Unique Users last 31 Days</div>
        <h2>{{paidSummaryData.active_month}}  <span class="text-gray-400 ml-8">(+{{nonpaidSummaryData.active_month}} Trials)</span></h2>
        <div class="text-rare2-dark flex text-2xl" v-if="false">
          <BaseIcon icon="north_east" themeName="rare2" :diameter="2" />
          <span class="ml-2"> +00 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./ActiveUsers.js"></script>
<style scoped src="./ActiveUsers.css"></style>