<template>
  <!-- Source material
    https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
    https://github.com/David-Desmaisons/vue-plotly
  -->
  <div :id="id" />
</template>
<script>
const Plotly = require("plotly.js-basic-dist-min");
import events from "Assets/plotly/events.js";
import methods from "Assets/plotly/methods.js";
import { camelize } from "Assets/plotly/helper.js";
export default {
  name: "Plotly",
  inheritAttrs: false,
  props: {
    chartData: {
      type: Array,
    },
    layout: {
      type: Object,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    const data = {}
    Object.defineProperty(data, "el", {
        get: _ => document.getElementById(this.id)
    });
    data.scheduled = null;
    data.innerLayout = { ...this.layout }
    return data;
  },
  mounted() {
    Plotly.newPlot(this.el, this.chartData, this.innerLayout, this.options); //{modeBarButtonsToRemove: ['toImage']});
    events.forEach((evt) => {
      this.el.on(evt.completeName, evt.handler(this));
    });
  },
  watch: {
    chartData: {
      handler() {
        this.schedule({ replot: true });
      },
      deep: true,
    },
    options: {
      handler(value, old) {
        if (JSON.stringify(value) === JSON.stringify(old)) {
          return;
        }
        this.schedule({ replot: true });
      },
      deep: true,
    },
    layout(layout) {
      this.innerLayout = { ...layout };
      this.schedule({ replot: false });
    },
  },
  computed: {
    options() {
      const optionsFromAttrs = Object.keys(this.$attrs).reduce((acc, key) => {
        acc[camelize(key)] = this.$attrs[key];
        //parse true/false values for chart options
        acc[camelize(key)] = acc[camelize(key)] === "false" ? false:acc[camelize(key)];
        acc[camelize(key)] = acc[camelize(key)] === "true" ? true:acc[camelize(key)];
        return acc;
      }, {});
      return {
        responsive: false,
        ...optionsFromAttrs,
      };
    },
  },
  beforeUnmount() {
    events.forEach((event) => this.el.removeAllListeners(event.completeName));
    Plotly.purge(this.el);
  },
  methods: {
    ...methods,
    onResize() {
      Plotly.Plots.resize(this.el);
    },
    schedule(context) {
      const { scheduled } = this;
      if (scheduled) {
        scheduled.replot = scheduled.replot || context.replot;
        return;
      }
      this.scheduled = context;
      this.$nextTick(() => {
        const {
          scheduled: { replot },
        } = this;
        this.scheduled = null;
        if (replot) {
          this.react();
          return;
        }
        this.relayout(this.innerLayout);
      });
    },
    toImage(options) {
      const allOptions = Object.assign(this.getPrintOptions(), options);
      return Plotly.toImage(this.el, allOptions);
    },
    downloadImage(options) {
      const filename = `plot--${new Date().toISOString()}`;
      const allOptions = Object.assign(this.getPrintOptions(), { filename }, options);
      return Plotly.downloadImage(this.el, allOptions);
    },
    getPrintOptions() {
      return {
        format: "png",
        width: this.el.clientWidth,
        height: this.clientHeight,
      };
    },
    react() {
      Plotly.react(this.el, this.chartData, this.innerLayout, this.options);
    },
  },
};
</script>