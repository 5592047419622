<template>
  <div>
    <BaseLoading v-if="isAuthorizing" class="full-view" />
  </div>
  <div v-if="!isAuthorizing" class="full-view flex flex-row">
    <div id="nav" v-if="isLoggedIn">
      <sidebar />
    </div>
    <div class="w-full p-8">
      <router-view />
    </div>
  </div>
</template>
<style src="Assets/styles.css"></style>


<script>
import sidebar from "Components/layout/Sidebar.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";

export default {
  name: "app",
  components: {
    sidebar,
    BaseLoading,
  },
  data() {
    return {
      isAuthorizing: true,
      // isAuthenticated: this.$store.getters.isLoggedIn,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    gapi.load("auth2", () => {
      const GoogleAuth = gapi.auth2.init({
        client_id: "168222209289-lo0p4sip1ouvuck4es4g8h4g6bofa9h3.apps.googleusercontent.com",
      });

      GoogleAuth.then(this.onInit, this.initFailure);
    });
  },

  methods: {
    onInit(gAuth) {
      this.$store.commit("setGAuth", gAuth);
      //if the user is already signed in allow entry
      if (gAuth.isSignedIn.get()) {
        // this.isAuthenticated = true;
        this.onSignIn(gAuth.currentUser.get());
      } else {
        this.$router.push("/login");
      }
      this.isAuthorizing = false;
    },

    initFailure() {
      console.log("gAuth Init failure");
      this.$router.push("/login");
      this.isAuthorizing = false;
    },

    onSignIn(googleUser) {
      // console.log(googleUser.getAuthResponse(true).access_token);
      const profile = googleUser.getBasicProfile();
      const authData = {
        name: profile.getName(),
        email: profile.getEmail(),
        accessToken: googleUser.getAuthResponse(true).access_token,
      };
      this.$store.commit("auth_success", authData);
    },
  },
};
</script>
