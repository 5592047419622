<template>
 <!-- full-view -->
  <div class="flex justify-center m-auto h-full">
    <div class="loading-pillars">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseLoading",
  data() {
    return {};
  },
};
</script>

<style>
.loading-pillars {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  @apply m-auto;
}
.loading-pillars div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 12px;
  @apply bg-main-lighter;
  animation: loading-pillars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading-pillars div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading-pillars div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading-pillars div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading-pillars {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
</style>